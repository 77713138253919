<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h4>Permission</h4>
              </div>
              <div class="col-6 text-right">
                <button
                  v-if="checkIsAccessible('permission', 'create')"
                  @click="createPermission"
                  class="btn btn-primary ml-2"
                >
                  Add New Permission
                </button>
              </div>
              <div class="col-12 mt-2">
                <table class="table table-stripe">
                  <thead>
                    <th>Name</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(permission, index) of permissionLists"
                      :key="index"
                    >
                      <td>{{ permission.name }}</td>
                      <td>
                        <a
                          class="fas fa-trash"
                          v-if="checkIsAccessible('permission', 'list')"
                          @click="deletePermission(permission)"
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-12 text-right">
                  <b-pagination
                    @input="getPermissions"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      ref="create-permission"
      @update_list="getPermissions"
    ></create-and-update>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CreateAndUpdate from "./CreateAndUpdate";
import PermissionService from "@/service/user/permission/PermissionService";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const permissionService = new PermissionService();
export default {
  name: "permission",
  validations: {
    permission_name: { required }
  },
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      permissionLists: [],
      page: null,
      total: null,
      perPage: null
    };
  },
  mounted() {
    this.getPermissions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Permission" },
      { title: "Manage Permission" }
    ]);
  },
  methods: {
    getPermissions() {
      permissionService.paginate(this.page).then(response => {
        this.permissionLists = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    deletePermission(permission) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            permissionService.delete(permission.id).then(response => {
              if (response.data.status === "OK") {
                this.getPermissions();
              }
            });
          }
        }
      });
    },
    createPermission() {
      this.$refs["create-permission"].openDialog();
    }
  }
};
</script>

<style scoped></style>
